import React from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import theme from '../styles/theme';

const StyledCheckbox = styled.div`
  .Checkbox__box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px solid ${theme.B500};
    background: ${theme.B400};
    border-radius: 5px;
    cursor: pointer;
    transition: background 300ms, border-color 300ms, box-shadow 300ms;

    ${props => props.disabled && 'opacity: 0.3'};
    ${props => props.disabled && 'cursor: not-allowed'};
  }

  .Checkbox__check {
    opacity: 0;
    width: 12px;
    height: 8px;
    border-left: 3px solid ${theme.font_secondary};
    border-bottom: 3px solid ${theme.font_secondary};
    transform: translate(0px, -2px) rotate(-45deg);
    transition: opacity 300ms, border-color 300ms;
  }

  input:checked + .Checkbox__box {
    background: ${theme.A500};
    border-color: ${theme.A500};
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  input:checked + .Checkbox__box .Checkbox__check {
    opacity: 1;
    border-color: ${theme.white};
  }

  input {
    display: none;
  }
`;

const Checkbox = ({ disabled, defaultValue, value, onChange, touched, setTouched, invalid, ...rest }) => (
  <StyledCheckbox disabled={disabled}>
    <label>
      <input type="checkbox" checked={value} defaultChecked={defaultValue} onChange={disabled ? noop : onChange} {...rest} />
      <div className="Checkbox__box">
        <div className="Checkbox__check" />
      </div>
    </label>
  </StyledCheckbox>
);

export default Checkbox;
