import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import uniq from 'lodash/uniq';

import { actions } from '../modules/base';
import theme from '../styles/theme';
import useInput from '../hooks/useInput';
import useLocalStorage from '../hooks/useLocalStorage';

import Button from '../components/Button';
import Input from './Input';
import ChipGroup from './ChipGroup';
import Chip from './Chip';

const StyledForm = styled.div`
  background: ${theme.B400};
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 18px;
  padding: 40px;
  width: 480px;

  .Form__title {
    text-align: center;
    margin: 0 0 25px;
    font-size: 26px;
    line-height: 32px;
    color: ${theme.font_primary};
  }

  .Form__button {
    margin-top: 25px;
  }
`;

const CreateRoomForm = ({ roomCreate }) => {
  const pastRoomNames = useLocalStorage('PAST_ROOM_NAMES', []);
  const roomName = useInput('', value => !!value);

  const onRoomCreate = e => {
    e.preventDefault();
    if (!roomName.touched) roomName.setTouched(true);
    if (roomName.invalid) return;
    addRoomName(roomName.value);
    roomCreate(roomName.value);
  };

  const addRoomName = name => {
    pastRoomNames.setValue(uniq(pastRoomNames.value.concat(name)));
  };

  const removeRoomName = nameToRemove => {
    pastRoomNames.setValue(pastRoomNames.value.filter(name => name !== nameToRemove));
  };

  return (
    <StyledForm>
      <div className="Form__title">Create a Scummy Room</div>
      <div className="Form__content">
        <form onSubmit={onRoomCreate}>
          <Input title="Room Name" placeholder="Super Duper Scrum Bois" autoFocus {...roomName} />
          {pastRoomNames.value.length > 0 && (
            <ChipGroup title="Recent Rooms">
              {pastRoomNames.value.map(name => (
                <Chip value={name} onClick={roomCreate} onDelete={removeRoomName} key={name} />
              ))}
            </ChipGroup>
          )}
          <Button className="Form__button" type="submit" disabled={roomName.touched && roomName.invalid}>
            Create Room
          </Button>
        </form>
      </div>
    </StyledForm>
  );
};
const mapDispatch = {
  roomCreate: actions.roomCreate,
};

export default connect(
  null,
  mapDispatch
)(CreateRoomForm);
