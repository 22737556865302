import styled from 'styled-components';

import theme from '../styles/theme';

const Table = styled.table`
  color: ${theme.font_primary};
  font-size: 14px;
  width: 100%;

  tr {
  }

  th {
    padding: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  td {
    padding: 10px;
  }

  tbody tr:nth-child(odd) {
    background: ${theme.B200};
  }
`;

export default Table;
