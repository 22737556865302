import { createActions } from 'redux-actions';

export default createActions(
  'CARDS_UPDATED',
  'CHAT_MESSAGE',
  'CHAT_INITIALIZED',
  'CHAT_UPDATED',
  'ESTIMATION_COMPLETE',
  'ESTIMATION_END',
  'ESTIMATION_NOTIFICATION',
  'ESTIMATION_REDO',
  'ESTIMATION_REVIEW',
  'ESTIMATION_START',
  'ESTIMATION_UPDATED',
  'PLACE_CARD',
  'ROOM_CREATE',
  'ROOM_CREATED',
  'ROOM_JOIN',
  'ROOM_JOINED',
  'ROOM_LEAVE',
  'ROOM_LEFT',
  'ROOM_NOT_FOUND',
  'ROSTER_UPDATED',
  'SETTINGS_UPDATE',
  'SETTINGS_UPDATED',
  'TOGGLE_SIDEBAR',
  'TYPING_START',
  'TYPING_STOP',
  'TYPING_UPDATED'
);
