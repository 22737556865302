import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

import Label from './Label';

const StyledInput = styled.div`
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }

  .Input__input {
    width: 100%;
    border: 1px solid transparent;
    background-color: ${theme.B600};
    border-color: ${theme.B700};
    color: ${theme.font_primary};
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 14px;
    transition: background-color 0.15s ease, border 0.15s ease;
    width: 100%;

    &:focus {
      box-shadow: 0 0 5px ${theme.A500};
    }

    &--invalid {
      border-color: ${theme.invalid};
    }

    &--invalid:focus {
      box-shadow: 0 0 5px ${theme.invalid};
    }
  }
`;

const Input = props => {
  const { className, title, touched, setTouched, invalid, ...rest } = props;

  return (
    <StyledInput className={className}>
      {title && <Label className="Input__label">{title}</Label>}
      <input className={`Input__input${touched && invalid ? ' Input__input--invalid' : ''}`} {...rest} />
    </StyledInput>
  );
};

export default Input;
