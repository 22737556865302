import React from 'react';
import styled from 'styled-components';

import theme from '../../styles/theme';
import CARD_STATUS from '../../constants/cardStatus';
import USER_ROLES from '../../constants/userRoles';
import hasRoles from '../../utils/hasRoles';

import BoardCard from './BoardCard';

const StyledCardStage = styled.div`
  .Stage__scene {
    perspective: 1000px;
    ${props => props.status === CARD_STATUS.INACTIVE && 'overflow: hidden'};

    &:hover {
      .Stage__card {
        ${props => props.status === CARD_STATUS.FLIPPED && `transform: rotateY(0)`};
      }
    }
  }

  .Stage__card {
    display: flex;
    position: absolute;
    width: 100%;
    padding-top: 141%;
    transition: transform 500ms;
    transform-style: preserve-3d;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
    z-index: 1;

    ${props => props.status === CARD_STATUS.INACTIVE && 'opacity: 0'};
    ${props => props.status === CARD_STATUS.INACTIVE && 'transform: scale(3)'};
    ${props => props.status === CARD_STATUS.PLACED && 'opacity: 1'};
    ${props => props.status === CARD_STATUS.PLACED && 'transform: scale(1)'};
    ${props => props.status === CARD_STATUS.PLACED && 'transition: all 200ms cubic-bezier(0.190, 1.000, 0.220, 1.000)'};
    ${props => props.status === CARD_STATUS.FLIPPED && 'transform: rotateY(180deg)'};
  }

  .Stage__placeholder {
    position: relative;
    width: 100%;
    padding-top: 141%;
    background: ${theme.B600};
    border-radius: 12px;
    z-index: 0;

    &--text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      font-weight: 700;
      font-size: 3vmin;
      color: rgba(255, 255, 255, 0.2);
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
      opacity: 0.4;
    }
  }

  .Stage__details {
    display: flex;
    justify-content: center;
  }

  .Stage__user {
    display: inline-flex;
    color: ${theme.font_primary};
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    background: ${theme.B700};
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    margin-top: 12px;
    padding: 4px 10px;
    border-radius: 10px;
    pointer-events: none;

    ${props => props.userColor && `color: ${props.userColor}`};
  }
`;

const BoardCardStage = ({ cardTheme, status, value, user }) => {
  const isSpectator = hasRoles(user, USER_ROLES.SPECTATOR);
  const { cardTheme: userTheme, cardX, cardY, cardZoom } = user;
  const cardPosition = {
    x: cardX,
    y: cardY,
    zoom: cardZoom,
  };

  return (
    <StyledCardStage status={status} userColor={user.color}>
      <div className="Stage__scene">
        <BoardCard
          className="Stage__card"
          cardTheme={cardTheme}
          cardPosition={cardPosition}
          useCustomValues={userTheme === 'Custom'}
          color={user.color}
          value={value}
          large
        />
        <div className="Stage__placeholder">{isSpectator && <div className="Stage__placeholder--text">SPECTATOR</div>}</div>
      </div>
      <div className="Stage__details">
        <div className="Stage__user">{user.username}</div>
      </div>
    </StyledCardStage>
  );
};

export default BoardCardStage;
