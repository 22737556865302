import React from 'react';
import styled from 'styled-components';

import { ChatInput, ChatRoster, ChatRoom } from './index';

const StyledChat = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: 0;
  width: 350px;
`;

const Chat = () => (
  <StyledChat>
    <ChatRoster />
    <ChatRoom />
    <ChatInput />
  </StyledChat>
);

export default Chat;
