import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const StyledChipGroup = styled.div`
  .ChipGroup__wrap {
    display: flex;
    flex-wrap: wrap;
    margin -4px;
  }

  .Chip {
    margin: 4px;
  }
`;

const ChipGroup = props => {
  const { children, title, ...rest } = props;

  return (
    <StyledChipGroup className="ChipGroup" {...rest}>
      {title && <Label className="ChipGroup__label">{title}</Label>}
      <div className="ChipGroup__wrap">{children}</div>
    </StyledChipGroup>
  );
};

export default ChipGroup;
