import CARD_STATUS from '../constants/cardStatus';
import hasRoles from '../utils/hasRoles';
import userRoles from '../constants/userRoles';

const isPlayerCard = card => !hasRoles(card.user, userRoles.SPECTATOR);
const isCardPlaced = card => card.status === CARD_STATUS.PLACED;
const cardMatches = (card, allCards) => {
  const firstCard = allCards[0];
  const isFlipped = card.status === CARD_STATUS.FLIPPED;
  const valuesMatch = firstCard.value === card.value;
  return isFlipped && valuesMatch;
};

export const allCardsPlaced = cards => {
  const playerCards = cards.filter(isPlayerCard);

  if (playerCards.length > 0) {
    return playerCards.every(isCardPlaced);
  } else {
    return cards.every(isCardPlaced);
  }
};

export const allCardsMatch = cards => {
  if (cards.length <= 1) return;

  const playerCards = cards.filter(isPlayerCard);

  if (playerCards.length > 1) {
    return playerCards.every(card => cardMatches(card, playerCards));
  } else {
    return cards.every(card => cardMatches(card, cards));
  }
};
