import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

const StyledTitle = styled.div`
  background: ${theme.B700};
  color: ${theme.font_primary};
  text-transform: uppercase;
  padding: 8px 10px;
`;

const Title = ({ children, ...props }) => <StyledTitle {...props}>{children}</StyledTitle>;

export default Title;
