import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

const StyledSection = styled.div`
  margin: 0 0 50px;

  &:last-child {
    margin: 0;
  }

  .Section__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${theme.font_secondary};
    padding: 0 0 5px;
    border-bottom: 2px solid ${theme.B400};
    margin: 0 0 20px;

    ${props => props.collapse && `margin: 0`};
  }
`;

const Section = props => {
  const { children, title, ...rest } = props;

  return (
    <StyledSection {...rest}>
      {title && <div className="Section__title">{title}</div>}
      <div className="Section__content">{children}</div>
    </StyledSection>
  );
};

export default Section;
