import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import theme from '../../styles/theme';
import { selectors } from '../../modules/base';

const StyledNugget = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  cursor: pointer;
  background: ${theme.B700};
  color: ${theme.font_primary};
  white-space: nowrap;
  box-sizing: border-box;
  transform: translate(-50%, -50%) rotate(-90deg);
  transform-origin: bottom;
  transition: all 500ms;

  &:hover {
    transform: translate(-50%, -50%) rotate(-90deg) scale(1.1);
  }

  .Nugget__text {
    padding: 5px 15px;
  }

  .Nugget__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 20px;
    height: 20px;
    padding: 0 5px;
    background: ${theme.A500};
    border: 1px solid ${theme.font_primary};
    color: ${theme.font_primary};
    font-size: 10px;
    font-weight: 700;
    border-radius: 999px;
    transform-origin: right;
    transform: rotate(90deg);
    opacity: 0;
    transition: opacity 200ms;

    ${props => props.hasNotification && 'opacity: 1'};
    ${props => props.hasNotification && 'animation: bobble infinite 6s'};
    ${props => props.hasNotification && 'animation-delay: 5s'};
  }

  @keyframes bobble {
    0%,
    33.33% {
      transform: rotate(90deg) scale(1);
    }

    16.66% {
      transform: rotate(90deg) scale(1.5);
    }
  }
`;

const ChatNugget = ({ notifications, ...props }) => {
  return (
    <StyledNugget hasNotification={notifications > 0} {...props}>
      <div className="Nugget__text">TOGGLE CHAT</div>
      <div className="Nugget__badge">{notifications}</div>
    </StyledNugget>
  );
};

const mapState = state => ({
  notifications: selectors.messageNotifications(state),
});

export default connect(mapState)(ChatNugget);
