import React, { useMemo } from 'react';
import styled from 'styled-components';

import theme, { spin, TRANSITION_FAST } from '../styles/theme';

import Label from '../components/Label';

const StyledTags = styled.div`
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  .Tags__tags {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
  }
`;

const Tag = styled.div`
  display: inline-flex;
  border-radius: 3px;
  width: 25px;
  height: 25px;
  border: 3px solid transparent;
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(1);
  transition: transform ${TRANSITION_FAST};

  &:hover {
    transform: scale(0.9);
  }

  ${props => props.active && `border-color: ${theme.font_primary}`};
`;

const generateColors = (baseColor, colorCount) => {
  const colors = [];
  const numColors = parseInt(colorCount, 10);
  const angle = 360 / numColors;
  for (let i = 0; i < numColors; i++) {
    colors.push(spin(baseColor, angle * i));
  }
  return colors;
};

const Tags = ({ title, count, value, onChange }) => {
  const colors = useMemo(() => {
    return generateColors(theme.A500, count);
  }, [count]);

  return (
    <StyledTags>
      {title && <Label>{title}</Label>}
      <div className="Tags__tags">
        {colors.map((color, i) => (
          <Tag key={i} active={value === color} onClick={() => onChange(color)} style={{ background: color }} />
        ))}
      </div>
    </StyledTags>
  );
};

Tags.defaultProps = {
  title: '',
  count: 10,
  value: null,
  onChange: () => {},
};

export default Tags;
