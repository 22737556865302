const { REACT_APP_ENV } = process.env;

export const ENVIRONMENT = REACT_APP_ENV || 'development';
export const LOCAL_STORAGE_PREFIX = 'SCUMMY_POKER';

const config = {
  local: {
    SOCKET_ENDPOINT: `/`,
  },
  development: {
    SOCKET_ENDPOINT: '/',
  },
  qa: {
    SOCKET_ENDPOINT: '/',
  },
  production: {
    SOCKET_ENDPOINT: '/',
  },
};

export default config[ENVIRONMENT];
