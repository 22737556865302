import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';

import theme from '../../styles/theme';

import { selectors as userSelectors } from '../../modules/user';
import { DEFAULT_COORD, DEFAULT_ZOOM } from '../../constants/defaults';

const StyledCard = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 141%;
  transition: transform 200ms ${theme.TRANSITION_TIMING_FUNCTION};
  transform-style: preserve-3d;
  border-radius: 12px;

  .Card__face {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 12px;
    backface-visibility: hidden;
    transform-style: preserve-3d;
  }

  .Card__front {
    background: ${theme.B900};
    transform: rotateY(180deg);
    ${props => props.color && `background: ${props.color}`};
  }

  .Card__back {
    background-color: ${theme.B800};
  }

  .Card__number {
    position: absolute;
    color: #dcdcdc;
    font-size: 7vmin;
    line-height: 1;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    user-select: none;
    ${props => props.large && `font-size: 14vmin`};

    @media screen and (max-width: 1600px) {
      font-size: 5vmin;
      ${props => props.large && `font-size: 14vmin`};
    }

    @media screen and (max-width: 1000px) {
      font-size: 4vmin;
      ${props => props.large && `font-size: 12vmin`};
    }

    &--c {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--tl,
    &--br {
      font-size: 1.5vw;
    }

    &--tl {
      top: 5%;
      left: 10%;
    }

    &--br {
      bottom: 5%;
      right: 10%;
    }
  }
`;

const StyledCardBack = styled.div`
  ${props => props.background && `background-image: url('${props.background}')`};
  background-color: ${theme.B800};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  transform-origin: center;
`;

const parseNumber = (coord, defaultValue) => {
  const parsed = parseInt(coord, 10);
  const value = isNumber(parsed) ? Math.round(parsed) : defaultValue;
  return value;
};

const calculatePosition = (x, y) => {
  const xPosition = (x + 100) / 200 * 100;
  const yPosition = (y + 100) / 200 * 100;
  return `${xPosition}% ${yPosition}%`;
};

const calculateScale = (zoom) => {
  return `scale(${zoom / 100})`;
}

const calculateTranslation = (x, y, zoom) => {
  const xScaled = x / 100;
  const yScaled = y / 100;
  const zoomDiff = (zoom - 100) / 2;
  const xTranslation = xScaled * zoomDiff * -1;
  const yTranslation = yScaled * zoomDiff * -1;
  return `translate(${xTranslation}%, ${yTranslation}%)`;
};

const CustomCardBack = styled(StyledCardBack).attrs(({ offsetX, offsetY, zoom: zoomValue }) => {
  const x = parseNumber(offsetX, DEFAULT_COORD);
  const y = parseNumber(offsetY, DEFAULT_COORD);
  const zoom = parseNumber(zoomValue, DEFAULT_ZOOM);

  const backgroundPosition = calculatePosition(x, y);
  const scale = calculateScale(zoom);
  const translate = calculateTranslation(x, y, zoom);

  return {
    style: {
      backgroundPosition,
      transform: `${translate} ${scale}`,
    },
  };
})`
  position: relative;
`;

const BoardCard = ({ cardPosition, cardTheme, useCustomValues, large, status, value, color, plain, ...props }) => {
  const cardColor = !plain ? color : undefined;
  const offsetX = useCustomValues ? cardPosition.x : 50;
  const offsetY = useCustomValues ? cardPosition.y : 50;
  const zoom = useCustomValues ? cardPosition.zoom : 100;
  const CardBack = useCustomValues ? CustomCardBack : StyledCardBack;

  return (
    <StyledCard background={cardTheme} color={cardColor} large={large} status={status} {...props}>
      <div className="Card__face Card__front">
        <div className="Card__number Card__number--c">{value}</div>
      </div>
      <div className="Card__face Card__back">
        <CardBack background={cardTheme} offsetX={offsetX} offsetY={offsetY} zoom={zoom} />
      </div>
    </StyledCard>
  );
};

BoardCard.defaultProps = {
  cardPosition: {
    x: 50,
    y: 50,
    zoom: 100,
  },
  useCustomValues: false,
};

const mapState = state => ({
  user: userSelectors.details(state),
});

export default connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(BoardCard);
