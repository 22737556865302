// Color
const REGEX_COLOR_HSL = 'hsl\\(\\d+,\\s*[\\d.]+%?,\\s*[\\d.]+%?\\)';

// Handlebars (For Replacements)
const REGEX_HANDLEBAR_KEY = 'a-zA-Z0-9';
const REGEX_HANDLEBAR_VALUE = 'a-zA-Z0-9\\s\\-._&()?½';

// Issue
const REGEX_ISSUE = 'a-zA-Z0-9\\s\\-._&()';

// Room
const REGEX_ROOM = 'a-zA-Z0-9\\s\\-._&';

// URL
const REGEX_URL =
  '((?:(?:(?:https?|ftp):)?//)(?:S+(?::S*)?@)?(?:(?!(?:10|127)(?:.d{1,3}){3})(?!(?:169.254|192.168)(?:.d{1,3}){2})(?!172.(?:1[6-9]|2d|3[0-1])(?:.d{1,3}){2})(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff].)+(?:[a-z\\u00a1-\\uffff]{2,}.?))(?::d{2,5})?(?:[/?#]S*)?)';

// Username
const REGEX_USERNAME = 'a-zA-Z0-9\\s\\-._&';

// Helpers
const REGEX_SPACES = '\\s{2,}';

module.exports = {
  REGEX_COLOR_HSL,
  REGEX_HANDLEBAR_KEY,
  REGEX_HANDLEBAR_VALUE,
  REGEX_ISSUE,
  REGEX_ROOM,
  REGEX_URL,
  REGEX_USERNAME,
  REGEX_SPACES,
};
