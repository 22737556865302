import { useState } from 'react';
import { LOCAL_STORAGE_PREFIX } from '../config';

export default (key = '', defaultValue = '') => {
  const [storage, setStorage] = useState(defaultValue);

  const getStorageValue = storage => {
    const store = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}_${key}`);
    return JSON.parse(store) || storage;
  };

  const setStorageValue = payload => {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}_${key}`, JSON.stringify(payload));
    return setStorage(payload);
  };

  return {
    value: getStorageValue(storage),
    setValue: setStorageValue,
  };
};
