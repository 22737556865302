import { handleActions } from 'redux-actions';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import omit from 'lodash/omit';
import uuid from 'uuid/v4';

import actions from './userActions';
import baseActions from './baseActions';
import { actions as socketActions } from './socket';

import { LOCAL_STORAGE_PREFIX } from '../config';

const USER_STORAGE_KEY = `${LOCAL_STORAGE_PREFIX}_USER`;

const storageUser = localStorage.getItem(USER_STORAGE_KEY) ? JSON.parse(localStorage.getItem(USER_STORAGE_KEY)) : { secret: uuid() };

// State
const defaultState = {
  card: undefined,
  details: storageUser,
  isAuthenticated: false,
};

// Selectors
export const selectors = {
  details: state => state.user.details,
  card: state => state.user.card,
  isAuthenticated: state => state.user.isAuthenticated,
};

// Actions
export { actions };

// Sagas
export function* userCreateSaga() {
  try {
    const isAuthenticated = yield select(selectors.isAuthenticated);
    const user = yield select(selectors.details);
    if (isAuthenticated) {
      yield put(socketActions.socketEvent({ event: 'user.reconnect', data: user }));
    } else {
      yield put(socketActions.socketEvent({ event: 'user.register', data: user }));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.userUpdated(e));
  }
}

export function* userUpdateSaga({ payload }) {
  try {
    yield put(socketActions.socketEvent({ event: 'user.update', data: payload }));
  } catch (e) {
    console.log(e);
    yield put(actions.userUpdated(e));
  }
}

// Saga
export function* userSaga() {
  yield all([takeLatest(socketActions.connected, userCreateSaga), takeLatest(actions.userUpdate, userUpdateSaga)]);
}

const updateUserStorage = user => {
  const fields = omit(user, ['card', 'cardId', 'roomId', 'roles']);
  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(fields));
};

// Reducer
export default handleActions(
  {
    [baseActions.placeCard]: {
      next(state, { payload: value }) {
        return {
          ...state,
          card: value,
        };
      },
    },
    [baseActions.roomJoined]: {
      next(state, { payload }) {
        const { user } = payload;
        return {
          ...state,
          details: user,
        };
      },
    },
    [baseActions.cardsUpdated]: {
      next(state, { payload: cards }) {
        const card = cards.find(card => card.id === state.details.id);
        if (!card) return state;

        return {
          ...state,
          card,
        };
      },
    },
    [baseActions.rosterUpdated]: {
      next(state, { payload: users }) {
        const details = users.find(user => user.id === state.details.id);
        if (!details) return state;

        const updatedDetails = { ...state.details, ...details };
        updateUserStorage(updatedDetails);
        return {
          ...state,
          details: omit(updatedDetails, 'card'),
        };
      },
    },
    [actions.userRegistered]: {
      next(state, { payload: details }) {
        updateUserStorage(details);
        return {
          ...state,
          details: omit(details, 'card'),
          isAuthenticated: true,
        };
      },
    },
    [actions.userUpdated]: {
      next(state, { payload: details }) {
        updateUserStorage(details);
        return {
          ...state,
          card: details.card,
          details: omit(details, 'card'),
        };
      },
    },
  },
  defaultState
);
