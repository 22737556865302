import React from 'react';
import styled from 'styled-components';

// import theme from '../styles/theme';

import ModalContainer from '../components/Modal.container';
import ToastContainer from '../components/Toast.container';

const StyledLayout = styled.div`
  .Site__content {
    min-height: 100vh;
  }
`;

const SiteLayout = ({ children }) => (
  <StyledLayout>
    <div className="Site__content">{children}</div>
    <ModalContainer />
    <ToastContainer />
  </StyledLayout>
);

export default SiteLayout;
