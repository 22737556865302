import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import theme from '../styles/theme';

const StyledChip = styled.div`
  position: relative;
  display: inline-block;
  background-color: ${theme.B600};
  padding: 6px 10px;
  height: 32px;
  border: 1px solid transparent;
  border-color: ${theme.B700};
  color: ${theme.font_primary};
  font-size: 14px;
  border-radius: 999px;
  opacity: 0.9;
  cursor: pointer;
  transition: all 200ms ${theme.TRANSITION_TIMING_FUNCTION};

  &:hover {
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 5px ${theme.A500};
  }

  &.Chip--deletable {
    padding-right: 32px;
  }

  .Chip__delete {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 6px 12px;
    font-size: 16px;
    transition: all 200ms ${theme.TRANSITION_TIMING_FUNCTION};

    &:hover,
    &:focus {
      color: ${theme.invalid};
    }
  }
`;
const Chip = props => {
  const { value, onClick, onDelete, ...rest } = props;

  const onChipClicked = event => {
    event.stopPropagation();
    return onClick(value);
  };

  const onDeleteClicked = event => {
    event.stopPropagation();
    return onDelete(value);
  };

  return (
    <StyledChip className={`Chip ${onDelete ? 'Chip--deletable' : ''}`} onClick={onChipClicked} {...rest}>
      {value}
      {onDelete && (
        <div className="Chip__delete" onClick={onDeleteClicked}>
          <FontAwesomeIcon icon="times" />
        </div>
      )}
    </StyledChip>
  );
};

export default Chip;
