import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { actions, selectors } from '../../modules/base';
import { actions as modalActions } from '../../modules/modal';
import { actions as userActions } from '../../modules/user';

import Button from '../Button';

const StyledActions = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }
  }
`;

const RoomActions = ({ room, roomLeave, showModal, userUpdate, isInRoom }) => {
  const onLeaveRoom = () => {
    showModal('confirm', {
      title: `Leave ${room.name}`,
      message: `Are you sure you want to leave ${room.name}?`,
      onClose: () => roomLeave(),
    });
  };

  const onUserDetails = () => {
    showModal('userDetails', {
      onClose: userUpdate,
    });
  };

  return (
    <StyledActions>
      <Button inline default icon="user" onClick={onUserDetails}>
        User Settings
      </Button>
      {isInRoom && (
        <Button inline onClick={onLeaveRoom}>
          Leave Room
        </Button>
      )}
    </StyledActions>
  );
};

const mapState = state => ({
  room: selectors.room(state),
  isInRoom: selectors.isInRoom(state),
});

const mapDispatch = {
  roomLeave: actions.roomLeave,
  showModal: modalActions.showModal,
  userUpdate: userActions.userUpdate,
};

export default connect(
  mapState,
  mapDispatch
)(RoomActions);
