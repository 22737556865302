import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import theme from '../../styles/theme';
import USER_ROLES from '../../constants/userRoles';
import hasRoles from '../../utils/hasRoles';

import { selectors } from '../../modules/base';
import { selectors as userSelectors } from '../../modules/user';

import Title from '../Title';

const StyledChatRoster = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 0 auto;

  .Chat__roster {
    flex: 1 1 auto;
    overflow: auto;
    max-height: 200px;

    &-item {
    }

    &-info {
      font-style: italic;
      font-size: 12px;
      color: ${theme.font_secondary};
    }
  }
`;

const RosterUser = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.6;
  padding: 2px 12px;
  border-left: 2px solid transparent;
  color: ${theme.font_primary};
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  transition: all 300ms;

  &:hover {
    padding-left: 15px;
    border-color: ${theme.A500};
  }

  .User__badge {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 100%;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

    ${props => props.color && `background: ${props.color}`};
  }

  .User__roles {
    display: inline-flex;
    margin-left: 8px;

    .User__icon {
      margin-right: 5px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .User__self {
    display: inline-flex;
    margin-left: 8px;
    font-size: 80%;
    color: ${theme.font_secondary};

    &:before {
      content: '(You)';
    }
  }
`;

const ChatRoster = ({ user, users }) => (
  <StyledChatRoster>
    <Title className="Chat__title">Scummy Boys ({users.length} In Room)</Title>
    <div className="Chat__roster">
      {users.map((rosterUser, i) => (
        <RosterUser key={rosterUser.id} color={rosterUser.color}>
          <div className="User__badge" />
          {rosterUser.username}
          {hasRoles(rosterUser, [USER_ROLES.OWNER, USER_ROLES.LEADER, USER_ROLES.SPECTATOR]) && (
            <div className="User__roles">
              {hasRoles(rosterUser, USER_ROLES.OWNER) && <FontAwesomeIcon className="User__icon" icon="lock" title="Owner" />}
              {hasRoles(rosterUser, USER_ROLES.LEADER) && <FontAwesomeIcon className="User__icon" icon="mouse-pointer" title="Leader" />}
              {hasRoles(rosterUser, USER_ROLES.SPECTATOR) && <FontAwesomeIcon className="User__icon" icon={['far', 'eye']} title="Spectator" />}
            </div>
          )}
          {user.id === rosterUser.id && <div className="User__self" />}
        </RosterUser>
      ))}
    </div>
  </StyledChatRoster>
);

const mapState = state => ({
  user: userSelectors.details(state),
  users: selectors.users(state),
});

const enhance = compose(
  withRouter,
  connect(mapState)
);

export default enhance(ChatRoster);
