import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import { DRAG_DIFF, DRAG_INPUT_DIFF, ZOOM_DIFF, ZOOM_INPUT_DIFF, MIN_COORD, MAX_COORD, MIN_ZOOM, MAX_ZOOM } from '../constants/defaults';

const limitValue = (value, min = 0, max = 100) => Math.min(Math.max(value, min), max);
const parseValue = (value, fallbackValue, min, max) => {
  const validNumber = !isNaN(value);
  if (validNumber) return limitValue(value, min, max);
  return limitValue(fallbackValue, min, max);
};

const useMouse = ({ ref, enabled, x, y, zoom: initZoom }) => {
  const [updates, setUpdates] = useState({ x: parseInt(x, 10), y: parseInt(y, 10), zoom: parseInt(initZoom, 10) });

  const coordinates = useRef({ x: updates.x, y: updates.y });
  const zoom = useRef({ value: updates.zoom });

  const update = (limitRefs = false) => {
    if (limitRefs) {
      coordinates.current.x = parseValue(coordinates.current.x, updates.x, MIN_COORD, MAX_COORD);
      coordinates.current.y = parseValue(coordinates.current.y, updates.y, MIN_COORD, MAX_COORD);
      zoom.current.value = parseValue(zoom.current.value, updates.zoom, MIN_ZOOM, MAX_ZOOM);
    }

    setUpdates({
      x: parseValue(coordinates.current.x, updates.x, MIN_COORD, MAX_COORD),
      y: parseValue(coordinates.current.y, updates.y, MIN_COORD, MAX_COORD),
      zoom: parseValue(zoom.current.value, updates.zoom, MIN_ZOOM, MAX_ZOOM),
    });
  };

  const onBlur = () => update(true);

  const updaterFn = (key, obj) => e => {
    const value = parseInt(e.target.value, 10);
    const validValue = !isNaN(value);
    obj[key] = validValue ? value : '';
    update();
  };

  const updater = {
    x: { type: 'number', min: MIN_COORD, max: MAX_COORD, step: DRAG_INPUT_DIFF, value: coordinates.current.x, onChange: updaterFn('x', coordinates.current), onBlur },
    y: { type: 'number', min: MIN_COORD, max: MAX_COORD, step: DRAG_INPUT_DIFF, value: coordinates.current.y, onChange: updaterFn('y', coordinates.current), onBlur },
    zoom: { type: 'number', min: MIN_ZOOM, max: MAX_ZOOM, step: ZOOM_INPUT_DIFF, value: zoom.current.value, onChange: updaterFn('value', zoom.current), onBlur },
  };

  const onDrag = throttle(dragEvent => {
    const movementX = dragEvent.movementX * DRAG_DIFF * -1;
    const movementY = dragEvent.movementY * DRAG_DIFF * -1;
    const x = limitValue(coordinates.current.x + movementX, MIN_COORD, MAX_COORD);
    const y = limitValue(coordinates.current.y + movementY, MIN_COORD, MAX_COORD);
    coordinates.current = { x, y };
    update();
  }, 50);

  const onScroll = throttle(scrollEvent => {
    const change = -1 * ZOOM_DIFF * Math.sign(scrollEvent.deltaY);
    zoom.current.value = limitValue(zoom.current.value + change, MIN_ZOOM, MAX_ZOOM);
    update();
  }, 50);

  const mouseDownListener = e => {
    e.preventDefault();
    document.addEventListener('mousemove', dragListener);
  };

  const mouseUpListener = e => {
    e.preventDefault();
    document.removeEventListener('mousemove', dragListener);
  };

  const dragListener = e => {
    e.preventDefault();
    onDrag(e);
  };

  const scrollListener = e => {
    e.preventDefault();
    onScroll(e);
  };

  useEffect(() => {
    if (!enabled) return;

    const elem = ref.current || window;
    if (elem) {
      elem.addEventListener('wheel', scrollListener);
      elem.addEventListener('mousedown', mouseDownListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        elem.removeEventListener('mousedown', mouseDownListener);
        elem.removeEventListener('wheel', scrollListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [enabled]);

  return {
    ...updates,
    updater,
  };
};

export default useMouse;
