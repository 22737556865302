import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import theme from '../../styles/theme';
import { selectors } from '../../modules/base';

const StyledNotifications = styled.div`
  position: absolute;
  top: 20px;
  left: 45px;
  right: 45px;
  z-index: 8;

  .Notifications__notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    max-width: 800px;
    margin: 0 auto;
    background: ${theme.A500};
    color: ${theme.font_primary};
    font-weight: 700;
    border-radius: 100px;
    padding: 8px 25px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    transform: translateY(-150%);
    transition: all 800ms;

    ${props => props.visible && `transform: translateY(0)`};
  }
`;

const BoardNotifications = ({ notification = '' }) => {
  return (
    <StyledNotifications visible={!!notification}>
      <div className="Notifications__notification">{notification}</div>
    </StyledNotifications>
  );
};

const mapState = state => ({
  notification: selectors.notification(state),
});

export default connect(mapState)(BoardNotifications);
