import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { REGEX_HANDLEBAR_KEY, REGEX_HANDLEBAR_VALUE, REGEX_URL } from '../../constants/regex';
import theme from '../../styles/theme';
import { selectors } from '../../modules/base';

import Title from '../Title';

const StyledChatRoom = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: 0;

  .Chat__messages {
    flex: 1 1 auto;
    overflow: auto;
  }

  .Chat__anchor {
    height: 25px;
  }
`;

const ChatMessage = styled.div`
  font-size: 14px;
  line-height: 1.3;
  padding: 4px 10px;
  min-height: 26px;
  border-left: 2px solid transparent;
  transition: all 300ms;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  ${props => props.type === 'info' && `font-size: 13px`};
  ${props => props.type === 'info' && `color: ${theme.font_secondary}`};

  ${props => props.type === 'event' && `color: ${theme.font_primary}`};

  ${props => props.type === 'result' && `color: ${theme.font_secondary}`};

  .Message__username {
    color: ${theme.font_primary};
    ${props => props.color && `color: ${props.color}`};
  }

  &:hover {
    border-color: ${theme.A500};
  }
`;

const renderMessage = (message, data) => {
  const regexUrl = new RegExp(REGEX_URL, 'gi');
  if (regexUrl.test(message)) {
    const parts = message.split(regexUrl);
    const result = parts.reduce((arr, match, i) => {
      if (!match.length) return arr;
      if (!match.match(regexUrl)) return [...arr, match];

      return [
        ...arr,
        <a key={`${match}_${i}`} href={match} target="_blank" rel="noopener noreferrer">
          {match}
        </a>,
      ];
    }, []);

    return result;
  }

  // Color Object Format
  const regexColor = new RegExp(`(\\{[${REGEX_HANDLEBAR_KEY}]+,\\s?[${REGEX_HANDLEBAR_VALUE}]+\\})`, 'gi');
  if (regexColor.test(message)) {
    const parts = message.split(regexColor);
    const result = parts.reduce((arr, match) => {
      if (!match.length) return arr;
      if (!match.match(regexColor)) return [...arr, match];

      const [dataAttr, name] = match.substring(1, match.length - 1).split(',');

      const userColor = dataAttr !== 'defaultColor' ? data[dataAttr] : theme.A500;
      if (!userColor) return [...arr, match];

      return [
        ...arr,
        <strong key={`${dataAttr}_${name}`} style={{ color: userColor }}>
          {name}
        </strong>,
      ];
    }, []);

    return result;
  }

  return message;
};

const ChatRoom = ({ messages }) => {
  const chatEl = useRef();

  const scrollToBottom = () => {
    chatEl.current.scrollTop = chatEl.current.scrollHeight + 1000;
  };

  useEffect(scrollToBottom, [messages]);

  const renderChatMessage = (message, i) => {
    if (['info', 'result'].includes(message.type)) {
      return (
        <ChatMessage key={`${message.id}_${i}`} type={message.type}>
          {renderMessage(message.message, message.data)}
        </ChatMessage>
      );
    }

    return (
      <ChatMessage key={`${message.id}_${i}`} color={message.color}>
        <strong className="Message__username">{message.username}:</strong> {renderMessage(message.message)}
      </ChatMessage>
    );
  };

  return (
    <StyledChatRoom>
      <Title className="Chat__title">Scummy Chat</Title>
      <div className="Chat__messages" ref={chatEl}>
        {messages.map(renderChatMessage)}
        <div className="Chat__anchor" />
      </div>
    </StyledChatRoom>
  );
};

const mapState = state => ({
  messages: selectors.messages(state),
});

export default connect(mapState)(ChatRoom);
