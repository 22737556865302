export const DEFAULT_COORD = 50;
export const DEFAULT_ZOOM = 100;

export const MIN_COORD = -100;
export const MAX_COORD = 100;
export const MIN_ZOOM = 100;
export const MAX_ZOOM = 500;

export const DRAG_DIFF = 1;
export const DRAG_INPUT_DIFF = 5;
export const ZOOM_DIFF = 10;
export const ZOOM_INPUT_DIFF = 10;
