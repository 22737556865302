import styled from 'styled-components';

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.span || 2}, 1fr);
  grid-gap: 0 30px;

  ${props => props.center && 'align-items: center'};

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

export default Columns;
