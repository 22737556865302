import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px 25px;

  .Header__title {
    text-align: center;
    font-weight: 700;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin: 0 10px;
  }

  .Header__branding {
    font-size: 30px;
    pointer-events: none;
  }

  .Header__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Header = ({ actions, title }) => (
  <StyledHeader>
    <div className="Header__branding">
      <span role="img" aria-label="Scummy Poker">
        🤙
      </span>
    </div>
    <div className="Header__title">{title}</div>
    <div className="Header__actions">{actions}</div>
  </StyledHeader>
);

export default Header;
