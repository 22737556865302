import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { selectors } from '../../modules/base';

import theme from '../../styles/theme';

import sizeMe from 'react-sizeme';
import Confetti from 'react-confetti';

const StyledReactions = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 200ms ${theme.TRANSITION_TIMING_FUNCTION};

  &.Reaction--placed {
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
  }
`;

const BoardReactions = sizeMe({
  monitorHeight: true,
  monitorWidth: true,
})(({ allCardsPlaced, allCardsMatch, size, ...rest }) => {
  return (
    <StyledReactions className={`${allCardsPlaced ? 'Reaction--placed' : ''}`}>
      {allCardsMatch && <Confetti {...size} numberOfPieces={100} gravity={0.3} opacity={1} recycle={false} />}
    </StyledReactions>
  );
});

const mapState = state => ({
  allCardsPlaced: selectors.allCardsPlaced(state),
  allCardsMatch: selectors.allCardsMatch(state),
});

export default connect(mapState)(BoardReactions);
