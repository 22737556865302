import { handleActions } from 'redux-actions';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import actions from './refActions';
import { actions as socketActions } from './socket';

// State
const defaultState = {
  cardThemes: {},
  loaded: false,
};

// Selectors
export const selectors = {
  cardThemes: state => state.ref.cardThemes,
  loaded: state => state.ref.loaded,
};

// Actions
export { actions };

// Sagas
export function* refCardSaga() {
  const loaded = yield select(selectors.loaded);
  if (!loaded) {
    yield put(
      socketActions.socketEvent({
        event: 'ref.cardThemes',
      })
    );
  }
}

// Saga
export function* refSaga() {
  yield all([takeLatest(socketActions.connected, refCardSaga)]);
}

// Reducer
export default handleActions(
  {
    // Connection Status
    [actions.refCardThemes]: {
      next(state, { payload: cardThemes }) {
        return {
          ...state,
          cardThemes,
          loaded: true,
        };
      },
    },
  },
  defaultState
);
