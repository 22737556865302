import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// import theme from '../styles/theme';
import { actions, selectors } from '../../modules/base';

import BoardCardStage from './BoardCardStage';
import BoardDeck from './BoardDeck';
import BoardNotifications from './BoardNotifications';
import BoardReactions from './BoardReactions';

const StyledPlaymat = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;

  .Playmat__content {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    overflow: auto;
    overflow-x: hidden;
  }

  .Playmat__grid {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: 25px;
    padding: 85px 15px calc(15vh * 1.41 + 15px);
    grid-template-columns: repeat(auto-fill, minmax(auto, 25vmin));
  }

  .Playmat__deck {
    position: absolute;
    bottom: -2vw;
    left: 0;
    right: 0;
  }

  .Playmat__background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.2);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    font-weight: 700;
    pointer-events: none;
    opacity: 0.3;
    user-select: none;
    z-index: -1;
  }
`;

const BoardPlaymat = ({ cards, estimation, placeCard }) => (
  <StyledPlaymat>
    <BoardNotifications />
    <BoardReactions />
    <div className="Playmat__background">Scummy Poker</div>
    <div className="Playmat__content">
      <div className="Playmat__grid">
        {cards.map(card => (
          <BoardCardStage key={card.id} {...card} />
        ))}
      </div>
    </div>
    <BoardDeck className="Playmat__deck" />
  </StyledPlaymat>
);

const mapState = state => ({
  cards: selectors.cards(state),
  estimation: selectors.estimation(state),
});

const mapDispatch = {
  placeCard: actions.placeCard,
};

export default connect(
  mapState,
  mapDispatch
)(BoardPlaymat);
