import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import theme from '../../styles/theme';
import deckMap, { EMOJIS, FIBONACCI } from '../../constants/decks';
import USER_ROLES from '../../constants/userRoles';
import hasRoles from '../../utils/hasRoles';

import { actions, selectors } from '../../modules/base';
import { selectors as userSelectors } from '../../modules/user';

import BoardCard from './BoardCard';

const StyledDeck = styled.div`
  padding: 0 35px;
  transition: transform 800ms;
  ${props => props.notEstimating && `transform: translateY(150%)`};

  .Deck__cards {
    display: grid;
    position: relative;
    grid-gap: 10px;
    justify-content: center;
    align-items: flex-end;
    grid-template-columns: repeat(${props => props.totalCards}, minmax(auto, 15vh));

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(auto, 100px));
    }
  }

  .Deck__card {
    transform: rotateY(180deg);
    cursor: pointer;

    .Card__front {
      transition: background 300ms;
    }

    &:hover {
      ${props => !props.disabled && `transform: rotateY(180deg) translateY(-1vw)`};
    }

    &-active,
    &-active:hover {
      transform: rotateY(180deg) translateY(-1.5vw) scale(1.1);

      .Card__front {
        background: ${theme.A500};
      }
    }

    @media screen and (max-width: 1200px) {
      padding-top: 70%;
    }
  }
`;

const parseCustomDeck = deckString => deckString.replace(/\s*,\s*/g, ',').split(',').filter(s => s.length > 0);

const BoardDeck = ({ card, estimation, type, placeCard, room, user, ...props }) => {
  const isSpectator = hasRoles(user, USER_ROLES.SPECTATOR);
  const isEstimating = ['ACTIVE', 'REVIEW'].includes(estimation);

  let deck = room.settings.cardDeck !== 'Custom' ? deckMap[room.settings.cardDeck] : parseCustomDeck(room.settings.cardDeckCustom);
  if (!deck) deck = FIBONACCI;
  if (isSpectator) deck = EMOJIS;

  const onPlaceCard = value => () => {
    if (!isEstimating) return;
    if (value === card.value) return;
    placeCard(value);
  };

  const isActiveCard = value => {
    return card && value === card.value;
  };

  return (
    <StyledDeck {...props} notEstimating={!isEstimating} totalCards={deck.length}>
      <div className="Deck__cards">
        {deck.map(deckCard => (
          <BoardCard
            key={deckCard}
            className={`Deck__card ${isActiveCard(deckCard) ? 'Deck__card-active' : ''}`}
            onClick={onPlaceCard(deckCard)}
            value={deckCard}
            plain
          />
        ))}
      </div>
    </StyledDeck>
  );
};

const mapState = state => ({
  card: userSelectors.card(state),
  estimation: selectors.estimation(state),
  room: selectors.room(state),
  user: userSelectors.details(state),
});

const mapDispatch = {
  placeCard: actions.placeCard,
};

export default connect(
  mapState,
  mapDispatch
)(BoardDeck);
