import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import theme from '../../styles/theme';
import { actions, selectors } from '../../modules/base';
import { selectors as userSelectors } from '../../modules/user';

import Input from '../Input';

const StyledChatInput = styled.div`
  padding: 0 15px;

  .Chat__input {
    margin: 0;
  }
`;

const ChatInfo = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  font-size: 10px;
  font-style: italic;
  color: ${theme.font_secondary};
  opacity: 0;
  transition: all 300ms;

  ${props => props.visible && `opacity: 1`};
`;

const ChatRoom = ({ typers, user, chatMessage, typingStart, typingStop }) => {
  const [timeout, setTimeoutValue] = useState();
  const [typing, setTyping] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');

  const getTyperText = (typers = []) => {
    const typersList = typers.filter(t => t.id !== user.id);
    const typerString = typersList.map(t => t.username).join(', ');

    if (!typersList.length) return '';
    if (typersList.length === 1) return `${typerString} is typing...`;
    if (typersList.length > 3) return 'A few dudes are typing...';
    return `${typerString} are typing...`;
  };

  const onChatSubmit = e => {
    e.preventDefault();

    if (currentMessage.trim().length === 0) return;
    if (typing) onTypingStop();

    chatMessage(currentMessage);
    setCurrentMessage('');
  };

  const onTypingStart = () => {
    setTyping(true);
    typingStart();
  };

  const onTypingStop = () => {
    setTyping(false);
    typingStop();
  };

  const onKeyUp = () => {
    clearTimeout(timeout);
    if (typing) setTimeoutValue(setTimeout(onTypingStop, 1000));
  };

  const onKeyPress = () => {
    clearTimeout(timeout);
    if (!typing) onTypingStart();
  };

  return (
    <StyledChatInput>
      <form onSubmit={onChatSubmit}>
        <Input
          className="Chat__input"
          placeholder="Say something scummy"
          maxLength="300"
          onChange={e => setCurrentMessage(e.target.value)}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          value={currentMessage}
        />
      </form>
      <ChatInfo visible={typers.length > 0}>{getTyperText(typers)}</ChatInfo>
    </StyledChatInput>
  );
};

const mapState = state => ({
  typers: selectors.typing(state),
  user: userSelectors.details(state),
});

const mapDispatch = {
  chatMessage: actions.chatMessage,
  typingStart: actions.typingStart,
  typingStop: actions.typingStop,
};

export default connect(
  mapState,
  mapDispatch
)(ChatRoom);
