import styled from 'styled-components';

import theme from '../styles/theme';

const Label = styled.label`
  display: flex;
  font-size: 12px;
  color: ${theme.font_secondary};
  letter-spacing: 0.5px;
  font-weight: 700;
  margin: 0 0 8px;
  text-transform: uppercase;

  &:last-child {
    margin: 0;
  }
`;

export default Label;
