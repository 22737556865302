import { useState } from 'react';

export default (initialValue = '', isValid = () => true) => {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const onChange = eventOrValue => {
    if (!touched) setTouched(true);
    const isEvent = typeof eventOrValue === 'object' && eventOrValue.target;
    if (isEvent) return setValue(eventOrValue.target.value);
    return setValue(eventOrValue);
  };
  const invalid = !isValid(value);
  return {
    value,
    onChange,
    touched,
    setTouched,
    invalid,
  };
};
