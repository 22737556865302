import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import 'typeface-quicksand';
import GlobalStyle from '../styles/global';
import NormalizeStyle from '../styles/normalize';

import store, { history } from '../lib/store';

import { HomeLayout, RoomLayout } from '../layouts';
import ModalContainer from '../components/Modal.container';
import ToastContainer from '../components/Toast.container';

const App = () => (
  <>
    <NormalizeStyle />
    <GlobalStyle />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path="/" component={HomeLayout} />
          <Route path="/rooms/:roomId" component={RoomLayout} />
          <Redirect to="/" />
        </Switch>
      </ConnectedRouter>
      <ModalContainer />
      <ToastContainer />
    </Provider>
  </>
);

export default App;
