export const FIBONACCI = ['½', '1', '2', '3', '5', '8', '13', '21', '34', '?', '☕️'];
export const FIBONACCI_MODIFIED = ['½', '1', '2', '3', '5', '8', '13', '20', '40', '?', '☕️'];
export const EMOJIS = ['👍', '👎', '🙃', '😍', '😒', '🙄', '😡', '😭', '🌝', '💩', '☕️'];
export const SHIRT_SIZES = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '?', '☕️'];

export default {
  Emojis: EMOJIS,
  Fibonacci: FIBONACCI,
  'Fibonacci Modified': FIBONACCI_MODIFIED,
  'Shirt Sizes': SHIRT_SIZES,
};
