import React from 'react';
import styled from 'styled-components';
import isObject from 'lodash/isObject';

import theme from '../styles/theme';

import Label from './Label';

const StyledSelect = styled.div`
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }

  .Select__label {
  }

  .Select__input {
    -webkit-appearance: none;
    width: 100%;
    border: 1px solid transparent;
    background-color: ${theme.B600};
    border-color: ${theme.B700};
    color: ${theme.font_primary};
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 14px;
    transition: background-color 0.15s ease, border 0.15s ease;
    width: 100%;

    &:focus {
      box-shadow: 0 0 5px ${theme.A500};
    }

    &--invalid {
      border-color: ${theme.invalid};
    }

    &--invalid:focus {
      box-shadow: 0 0 5px ${theme.invalid};
    }
  }
`;

const Select = props => {
  const { className, options, title, touched, setTouched, invalid, ...rest } = props;

  return (
    <StyledSelect className={className}>
      {title && <Label className="Select__label">{title}</Label>}
      <select className={`Select__input${touched && invalid ? ' Select__input--invalid' : ''}`} {...rest}>
        {options.map((o, i) => {
          if (isObject(o)) {
            return (
              <option key={o.label} value={o.value}>
                {o.label}
              </option>
            );
          }

          return (
            <option key={`${i}_${o}`} value={o}>
              {o}
            </option>
          );
        })}
      </select>
    </StyledSelect>
  );
};

export default Select;
