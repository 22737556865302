import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { all, call, put, take } from 'redux-saga/effects';
import { createActions } from 'redux-actions';

import { ENVIRONMENT } from '../config';
import baseActions from './baseActions';
import refActions from './refActions';
import userActions from './userActions';

// Actions
export const actions = createActions('CONNECTED', 'DISCONNECTED', 'SOCKET_EVENT');

// Channel
function initSocketChannel(socket) {
  return eventChannel(emitter => {
    socket.on('connect', () => {
      return emitter(actions.connected());
    });

    socket.on('disconnect', () => {
      return emitter(actions.disconnected());
    });

    socket.on('room.created', data => {
      return emitter(baseActions.roomCreated(data.room));
    });

    socket.on('room.joined', data => {
      return emitter(baseActions.roomJoined(data));
    });

    socket.on('room.notFound', data => {
      return emitter(baseActions.roomNotFound(data));
    });

    socket.on('estimation.status', data => {
      return emitter(baseActions.estimationNotification(data.notification));
    });

    socket.on('estimation.update', data => {
      return emitter(baseActions.estimationUpdated(data.estimation));
    });

    socket.on('cards.update', data => {
      return emitter(baseActions.cardsUpdated(data.cards));
    });

    socket.on('card.updated', data => {
      return emitter(userActions.userCardUpdated(data.card));
    });

    socket.on('chat.update', data => {
      return emitter(baseActions.chatUpdated(data.messages));
    });

    socket.on('roster.update', data => {
      return emitter(baseActions.rosterUpdated(data.users));
    });

    socket.on('settings.update', data => {
      return emitter(baseActions.settingsUpdated(data.settings));
    });

    socket.on('typing.update', data => {
      return emitter(baseActions.typingUpdated(data.users));
    });

    socket.on('user.registered', data => {
      return emitter(userActions.userRegistered(data.user));
    });

    socket.on('user.updated', data => {
      return emitter(userActions.userUpdated(data.user));
    });

    socket.on('ref.cardThemesData', data => {
      return emitter(refActions.refCardThemes(data.themes));
    });

    socket.on('error.notAuthorized', () => {
      console.error('NOT AUTHORIZED');
    });

    socket.on('app.error', data => {
      console.error('Websocket - Error', data);
    });

    return () => socket.close();
  });
}

// Sagas
function* inboundSocketSaga(socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}

function* outboundSocketSaga(socket) {
  while (true) {
    const { payload } = yield take(actions.socketEvent);
    const { event, data } = payload;
    socket.emit(event, data);
  }
}

export function* websocketSaga() {
  const socket = io({ transports: ['websocket'] });
  if (ENVIRONMENT === 'development') window.socket = socket;

  console.log('Websocket - Connecting');
  socket.on('connect', () => console.log('Websocket - Connected'));
  socket.on('disconnect', () => console.log('Websocket - Disconnected'));
  const socketChannel = yield call(initSocketChannel, socket);
  yield all([call(outboundSocketSaga, socket), call(inboundSocketSaga, socketChannel)]);
}
