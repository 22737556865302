import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import theme from '../styles/theme';

import Emoji from '../components/Emoji';
import CreateRoomForm from '../components/CreateRoomForm';

const StyledLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  padding: 15px;
  background: ${theme.B500};

  .Home__branding {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin: -80px 0 50px;
  }

  .Home__brand {
    text-transform: uppercase;
    font-size: 16px;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    margin-top: 3px;
  }

  .Home__content {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    min-height: 0;
  }

  .Home__footer {
    flex: 0 0 auto;
    font-size: 12px;
    text-align: center;
    padding: 5px;

    .emoji {
      display: inline-block;
      font-size: 10px;
      margin: 0 5px;
      color: ${theme.A500};
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const HomeLayout = ({ createRoom }) => {
  return (
    <StyledLayout>
      <div className="Home__content">
        <div className="Home__branding">
          <Emoji label="Scummy Poker" value="🤙" />
          <div className="Home__brand">SCUMMY POKER</div>
        </div>
        <CreateRoomForm />
      </div>
      <div className="Home__footer">
        Made with
        <FontAwesomeIcon className="emoji" icon="heart" />
        by{' '}
        <a href="https://christieman.com" target="_blank" rel="noopener noreferrer">
          Ducky
        </a>
        {'  -  '}
        <a href="https://www.paypal.me/datducky" target="_blank" rel="noopener noreferrer">
          Donate
        </a>
      </div>
    </StyledLayout>
  );
};

export default HomeLayout;
