import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';

import modal from './modal';
import base, { baseSaga } from './base';
import ref, { refSaga } from './ref';
import { websocketSaga } from './socket';
import toast from './toast';
import user, { userSaga } from './user';

export function* rootSaga() {
  yield all([baseSaga(), refSaga(), userSaga(), websocketSaga()]);
}

export const rootReducer = history => {
  return combineReducers({
    router: connectRouter(history),
    base,
    modal,
    ref,
    toast,
    user,
  });
};
