import React from 'react';
import { handleActions } from 'redux-actions';
import { all, put, select, take, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';

import actions from './baseActions';
import { actions as socketActions } from './socket';
import { actions as toastActions } from './toast';
import { selectors as userSelectors } from './user';

import { allCardsPlaced, allCardsMatch } from '../lib/selectorLogic';
import { getLastMessage, getLastUserMessage, getUserMessagesSince } from '../utils/messages';

// State
const defaultState = {
  room: {},
  estimation: 'INACTIVE',
  cards: [],
  messages: [],
  users: [],
  typing: [],
  lastMessageId: null,
  isFirstChatUpdate: true,
  isSidebarVisible: true,
  notification: null,
  isConnected: false,
  isInRoom: false,
};

// Selectors
export const selectors = {
  cards: state => state.base.cards,
  isSidebarVisible: state => state.base.isSidebarVisible,
  estimation: state => state.base.estimation,
  messages: state => state.base.messages,
  notification: state => state.base.notification,
  room: state => state.base.room,
  typing: state => state.base.typing,
  users: state => state.base.users,
  lastMessageId: state => state.base.lastMessageId,
  isFirstChatUpdate: state => state.base.isFirstChatUpdate,
  isConnected: state => state.base.isConnected,
  isInRoom: state => state.base.isInRoom,
  allCardsPlaced: state => allCardsPlaced(state.base.cards),
  allCardsMatch: state => allCardsMatch(state.base.cards),
};

selectors.messageNotifications = createSelector(
  [selectors.messages, selectors.isSidebarVisible, selectors.lastMessageId],
  (messages, isSidebarVisible, lastMessageId) => {
    if (isSidebarVisible) return 0;

    const messagesSince = getUserMessagesSince(messages, lastMessageId);
    return messagesSince.length;
  }
);

selectors.isRoomOwner = createSelector(
  [selectors.room, userSelectors.details],
  (room, user) => room.ownerId === user.id
);

// Actions
export { actions };

const notification = new Audio('/audio/notification.m4a');
const quackification = new Audio('/audio/quack.mp3');

const getNotification = () => {
  return Math.random() < 0.03 ? quackification : notification;
};

// Sagas
export function* chatMessageSaga({ payload: message }) {
  const room = yield select(selectors.room);
  yield put(
    socketActions.socketEvent({
      event: 'chat.message',
      data: {
        roomId: room.id,
        message,
      },
    })
  );
}

export function* chatUpdatedSaga({ payload: messages }) {
  try {
    const isFirstChatUpdate = yield select(selectors.isFirstChatUpdate);
    const isSidebarVisible = yield select(selectors.isSidebarVisible);
    const user = yield select(userSelectors.details);
    const notification = getNotification();

    const shouldNotify = (message, user) => {
      if (message.type !== 'message') return false;
      if (user.id === message.userId) return false;
      if (!document.hasFocus()) return true;
      return !isSidebarVisible;
    };

    if (isFirstChatUpdate) {
      yield put(actions.chatInitialized());
      return;
    }

    const message = getLastMessage(messages);
    if (!message) return;

    const playNotification = shouldNotify(message, user);
    if (playNotification) {
      notification.currentTime = 0;
      yield notification.play();
    }
  } catch(e) {}
}

export function* roomCreateSaga({ payload: name }) {
  try {
    yield put(socketActions.socketEvent({ event: 'room.create', data: { name } }));

    const { payload: room } = yield take(actions.roomCreated);
    yield put(push(`/rooms/${room.id}`));
  } catch (e) {
    console.error(e);
  }
}

export function* roomJoinSaga({ payload: roomId }) {
  try {
    const params = new URLSearchParams(window.location.search);
    const roomSecret = params.get('secret');

    yield put(
      socketActions.socketEvent({
        event: 'room.join',
        data: {
          roomId,
          roomSecret,
        },
      })
    );
    yield put(push(`/rooms/${roomId}`));
  } catch (e) {
    console.error(e);
  }
}

export function* roomLeaveSaga() {
  try {
    yield put(
      socketActions.socketEvent({
        event: 'room.leave',
      })
    );
    yield put(push('/'));
    yield put(
      toastActions.createToast('success', {
        title: 'Room Left',
        message: (
          <>
            <div>You've successfully left the room.</div>
            <div>Happy estimation!</div>
          </>
        ),
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* roomNotFoundSaga() {
  try {
    yield put(push('/'));
    yield put(
      toastActions.createToast('alert', {
        title: 'Room Not Found',
        message: (
          <>
            <div>Sorry, that room doesn't appear to exist anymore.</div>
            <div>Rooms are deleted 24 hours after no activity is shown.</div>
          </>
        ),
        timeout: 6000,
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* estimationStartSaga({ payload: { issue, newSession } }) {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'estimation.start',
        data: {
          roomId: room.id,
          issue,
          newSession,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* estimationReviewSaga() {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'estimation.review',
        data: {
          roomId: room.id,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* estimationRedoSaga() {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'estimation.redo',
        data: {
          roomId: room.id,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* estimationCompleteSaga({ payload: points }) {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'estimation.complete',
        data: {
          roomId: room.id,
          points,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* estimationEndSaga() {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'estimation.end',
        data: {
          roomId: room.id,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* placeCardSaga({ payload: value }) {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'estimation.placeCard',
        data: {
          roomId: room.id,
          value,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* settingsUpdateSaga({ payload }) {
  try {
    const room = yield select(selectors.room);
    yield put(
      socketActions.socketEvent({
        event: 'room.settingsUpdate',
        data: {
          roomId: room.id,
          settings: payload,
        },
      })
    );
  } catch (e) {
    console.error(e);
  }
}

export function* typingStartSaga() {
  yield put(
    socketActions.socketEvent({
      event: 'typing.start',
    })
  );
}

export function* typingStopSaga() {
  yield put(
    socketActions.socketEvent({
      event: 'typing.stop',
    })
  );
}

// Saga
export function* baseSaga() {
  yield all([
    takeLatest(actions.chatMessage, chatMessageSaga),
    takeLatest(actions.chatUpdated, chatUpdatedSaga),
    takeLatest(actions.roomCreate, roomCreateSaga),
    takeLatest(actions.roomJoin, roomJoinSaga),
    takeLatest(actions.roomLeave, roomLeaveSaga),
    takeLatest(actions.roomNotFound, roomNotFoundSaga),
    takeLatest(actions.estimationStart, estimationStartSaga),
    takeLatest(actions.estimationReview, estimationReviewSaga),
    takeLatest(actions.estimationRedo, estimationRedoSaga),
    takeLatest(actions.estimationComplete, estimationCompleteSaga),
    takeLatest(actions.estimationEnd, estimationEndSaga),
    takeLatest(actions.placeCard, placeCardSaga),
    takeLatest(actions.settingsUpdate, settingsUpdateSaga),
    takeLatest(actions.typingStart, typingStartSaga),
    takeLatest(actions.typingStop, typingStopSaga),
  ]);
}

// Reducer
export default handleActions(
  {
    // Connection Status
    [socketActions.connected]: {
      next(state) {
        return {
          ...state,
          isConnected: true,
        };
      },
    },
    [socketActions.disconnected]: {
      next(state) {
        return {
          ...state,
          isConnected: false,
        };
      },
    },

    // Room Details/Status
    [actions.roomJoin]: {
      next(state) {
        return {
          ...state,
          cards: defaultState.cards,
          messages: defaultState.messages,
          users: defaultState.users,
        };
      },
    },
    [actions.roomJoined]: {
      next(state, { payload }) {
        const { room } = payload;
        return {
          ...state,
          room,
          isInRoom: true,
        };
      },
    },
    [actions.roomLeave]: {
      next(state) {
        return {
          ...state,
          cards: defaultState.cards,
          messages: defaultState.messages,
          users: defaultState.users,
        };
      },
    },
    [actions.roomLeft]: {
      next(state) {
        return {
          ...state,
          room: defaultState.room,
          isInRoom: false,
        };
      },
    },

    // Various States
    [actions.toggleSidebar]: {
      next(state) {
        let lastMessageId = state.lastMessageId;
        const isSidebarVisible = !state.isSidebarVisible;

        if (isSidebarVisible) {
          const recentMessage = getLastUserMessage(state.messages) || {};
          lastMessageId = recentMessage.id;
        }

        return {
          ...state,
          lastMessageId,
          isSidebarVisible,
        };
      },
    },
    [actions.cardsUpdated]: {
      next(state, { payload: cards }) {
        return {
          ...state,
          cards,
        };
      },
    },
    [actions.chatInitialized]: {
      next(state) {
        return {
          ...state,
          isFirstChatUpdate: false,
        };
      },
    },
    [actions.chatUpdated]: {
      next(state, { payload: messages }) {
        const recentMessage = getLastUserMessage(messages) || {};
        const lastMessageId = state.isSidebarVisible ? recentMessage.id : state.lastMessageId;
        return {
          ...state,
          messages,
          lastMessageId,
        };
      },
    },
    [actions.estimationNotification]: {
      next(state, { payload: notification }) {
        return {
          ...state,
          notification,
        };
      },
    },
    [actions.estimationUpdated]: {
      next(state, { payload: estimation }) {
        return {
          ...state,
          estimation,
        };
      },
    },
    [actions.rosterUpdated]: {
      next(state, { payload: users }) {
        return {
          ...state,
          users,
        };
      },
    },
    [actions.settingsUpdated]: {
      next(state, { payload: settings }) {
        return {
          ...state,
          room: {
            ...state.room,
            settings,
          },
        };
      },
    },
    [actions.typingUpdated]: {
      next(state, { payload: typing }) {
        return {
          ...state,
          typing,
        };
      },
    },
  },
  defaultState
);
