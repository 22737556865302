import React from 'react';
import styled from 'styled-components';

import { BoardActions, BoardPlaymat } from './index';

const StyledBoard = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  box-shadow: -3px 0 5px rgba(0, 0, 0, 0.3);
`;

const Board = () => (
  <StyledBoard>
    <BoardPlaymat />
    <BoardActions />
  </StyledBoard>
);

export default Board;
