import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ESTIMATION_STATUS from '../../constants/estimationStatus';
import USER_ROLES from '../../constants/userRoles';
import hasRoles from '../../utils/hasRoles';

import { actions, selectors } from '../../modules/base';
import { actions as modalActions } from '../../modules/modal';
import { selectors as userSelectors } from '../../modules/user';

import Button from '../Button';

const StyledActionBar = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 15px 25px;

  .Actions__left {
    margin-right: 10px;
  }

  .Actions__right {
    display: flex;
    margin-left: auto;

    button {
      margin: 0 3px;
    }
  }
`;

const BoardActions = ({
  details,
  estimation,
  allCardsPlaced,
  showModal,
  estimationComplete,
  estimationReview,
  estimationRedo,
  estimationStart,
  estimationEnd,
  settingsUpdate,
}) => {
  if (!hasRoles(details, USER_ROLES.OWNER) && !hasRoles(details, USER_ROLES.LEADER)) {
    return null;
  }

  const onEstimationStart = newSession => {
    showModal('estimationStart', {
      onClose: issue => {
        estimationStart({ issue, newSession });
      },
    });
  };

  const onEstimationReview = () => {
    showModal('confirm', {
      title: 'Submit Estimation',
      message: 'Are you ready to flip the cards and discuss estimations?',
      closeText: 'Yep, Flip Cards',
      onClose: estimationReview,
    });
  };

  const onEstimationRedo = () => {
    showModal('confirm', {
      title: 'Re-Estimate Issue',
      message: 'Are you sure you want to redo this hand of cards?',
      closeText: 'Yep, Estimate Again',
      onClose: estimationRedo,
    });
  };

  const onEstimationComplete = () => {
    showModal('estimationStop', {
      onClose: estimationComplete,
    });
  };

  const onEstimationEnd = () => {
    showModal('confirm', {
      title: 'Finish Estimation',
      message: 'Are you all done with backlog grooming/estimation?',
      closeText: 'Yep, Finished',
      onClose: estimationEnd,
    });
  };

  const onRoomSettings = () => {
    showModal('roomSettings', {
      title: 'Room Settings',
      closeText: 'Save Settings',
      onClose: settingsUpdate,
    });
  };

  return (
    <StyledActionBar>
      <div className="Actions__left">
        {hasRoles(details, 'OWNER') && (
          <Button inline default icon="cog" onClick={onRoomSettings}>
            Room Settings
          </Button>
        )}
      </div>
      <div className="Actions__right">
        {estimation === ESTIMATION_STATUS.INACTIVE && (
          <Button inline onClick={() => onEstimationStart(true)}>
            Start Estimation
          </Button>
        )}
        {estimation === ESTIMATION_STATUS.ACTIVE && (
          <Button inline onClick={onEstimationReview} default={!allCardsPlaced}>
            Review Estimations
          </Button>
        )}
        {estimation === ESTIMATION_STATUS.REVIEW && (
          <Button inline onClick={onEstimationRedo} default>
            Re-Estimate Issue
          </Button>
        )}
        {estimation === ESTIMATION_STATUS.REVIEW && (
          <Button inline onClick={onEstimationComplete}>
            Submit Estimation
          </Button>
        )}
        {estimation === ESTIMATION_STATUS.COMPLETE && (
          <Button inline onClick={onEstimationEnd} default>
            Finish Estimation
          </Button>
        )}
        {estimation === ESTIMATION_STATUS.COMPLETE && (
          <Button inline onClick={() => onEstimationStart(false)}>
            Next Issue
          </Button>
        )}
      </div>
    </StyledActionBar>
  );
};

const mapState = state => ({
  details: userSelectors.details(state),
  estimation: selectors.estimation(state),
  allCardsPlaced: selectors.allCardsPlaced(state),
});

const mapDispatch = {
  estimationStart: actions.estimationStart,
  estimationReview: actions.estimationReview,
  estimationRedo: actions.estimationRedo,
  estimationComplete: actions.estimationComplete,
  estimationEnd: actions.estimationEnd,
  settingsUpdate: actions.settingsUpdate,
  showModal: modalActions.showModal,
};

export default connect(
  mapState,
  mapDispatch
)(BoardActions);
