import findIndex from 'lodash/findIndex';

export const getUserMessages = messages => {
  const userMessages = messages.filter(message => message.type === 'message');
  return userMessages;
};

export const getUserMessagesSince = (messages, lastMessageId) => {
  const userMessages = getUserMessages(messages);
  const lastMessageIndex = findIndex(userMessages, { id: lastMessageId });
  const messageIndex = lastMessageIndex > -1 ? lastMessageIndex + 1 : 0;
  return userMessages.slice(messageIndex);
};

export const getLastMessage = messages => {
  const [message] = messages.slice(-1);
  return message;
};

export const getLastUserMessage = messages => {
  const userMessages = messages.filter(message => message.type === 'message');
  const [message] = userMessages.slice(-1);
  return message;
};
